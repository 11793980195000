import styled from 'styled-components';
import { Box, TextField, withStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ReactComponent as Filter } from '../../assets/filter.svg';
import { ReactComponent as Cart } from '../../assets/cart.svg';
import { ReactComponent as MagnifyingGlass } from '../../assets/magnifyingGlass.svg';

export const SubHeaderContainer = styled(Box)`
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  top: 0;
  z-index: 1;
  position: -webkit-sticky;
  position: sticky;
  background-color: var(--primaryLighter);
`;

export const LeftContainer = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RightContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

export const CategoryIconContainer = styled(Filter)`
  height: 20px;
  margin-right: 10px;
`;

export const SearchMagnifyingGlass = styled(MagnifyingGlass)`
  position: relative;
  right: 30px;
  cursor: pointer;
`;

export const CategoryContainer = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: none;

  @media screen and (min-width: 450px) {
    display: block;
  }
`;

export const AutocompleteContainer = styled(Autocomplete)`
  min-width: 115px;
  max-width: 250px;
  width: 70%;
  margin-left: 20px;
`;

export const CartIcon = styled(Cart)`
  height: 38px;
  margin-left: 10px;
`;

export const CartBox = styled.div`
  cursor: pointer;
`;

export const CartCount = styled.div`
  background-color: var(--neutral);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  color: var(--primaryFontColor);
  position: absolute;
  right: 7px;
  bottom: 5px;
`;

export const CssTextField = withStyles({
  root: {
    backgroundColor: 'var(--neutral)',
    borderRadius: '3px',
    '& label': {
      color: 'var(--neutralDarker)',
      opacity: 0.6,
    },
    '& .Mui-focused': {
      color: 'var(--neutralDarker)',
      opacity: 0.6,
    },
    '& .MuiOutlinedInput-root': {
      width: 220,
      borderWidth: 0,
      '& fieldset': {
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderWidth: 0,
      },
      '&.Mui-focused fieldset': {
        borderWidth: 0,
      },
    },
  },
})(TextField);
