import { IconButton } from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as CloseBold } from '../../assets/closeBold.svg';

export const CatalogBox = styled.div`
  padding: 16px;
`;

export const IconButtonContainer = styled(IconButton)`
  width: 17px;
  height: 17px;
  margin: 5px 0 0 10px !important;
  padding: 4px !important;
  background-color: var(--primary) !important;

  & svg {
    width: 100%;
  }
`;

export const BackgroundDecorationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.8;
`;

export const DeleteFilter = styled(CloseBold)``;
