import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { RecoilRoot } from 'recoil';
import ShopPage from './pages/ShopPage';
import { muiDialogOveride } from './components/ValidationDialog/ValidationDialog.style';
import { client } from './services/apollo';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeComponent } from './theme/color';
import HomePage from './pages/HomePage/HomePage';

const theme = createMuiTheme({
  palette: {
    // regardez pas siouplai
    primary: {
      main: 'hsla(var(--primaryFont), 100, 100, 1);',
    },
    secondary: {
      main: 'hsla(var(--primary), 100, 100, 1);',
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'var(--secondaryFont)',
    },
  },
  overrides: {
    ...muiDialogOveride,
  },
});

function App(): JSX.Element {
  return (
    <React.StrictMode>
      <Router>
        <RecoilRoot>
          <ApolloProvider client={client}>
            <ThemeComponent />
            <ThemeProvider theme={theme}>
              <Route exact path="/" component={HomePage} />
              <Route path="/:shopName/" component={ShopPage} />
            </ThemeProvider>
          </ApolloProvider>
        </RecoilRoot>
      </Router>
    </React.StrictMode>
  );
}

export default App;
