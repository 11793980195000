import styled from 'styled-components';
import { IconButton, Button, withStyles, InputBase } from '@material-ui/core';
import { ReactComponent as CloseBold } from '../../assets/closeBold.svg';

export const ButtonQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const ButtonQuantity = styled(Button)`
  padding: 6.5px 10px !important;
  min-width: 25px !important;
  background-color: var(--primaryLighter) !important;
  border-color: var(--primaryLighter) !important;
`;

export const IconDeleteContainer = styled.div`
  padding: 10px;
`;

export const ButtonDelete = styled(IconButton)`
  width: 17px;
  height: 17px;
  padding: 4px !important;
  background-color: var(--primary) !important;

  & svg {
    width: 100%;
  }
`;

export const DeleteFilter = styled(CloseBold)``;

export const BootstrapInput = withStyles((theme) => ({
  root: {
    width: 96,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'var(--primaryLighter)',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'var(--primaryFont)',
    '&:focus': {
      borderRadius: 4,
      borderColor: 'var(--primaryLighter)',
      backgroundColor: 'var(--primaryLighter)',
      boxShadow: `0 0 0 0.2rem var(--neutralLight)`,
    },
  },
}))(InputBase);
