import currency from 'currency.js';
import { CartItem } from '../models/dto';
import { DisplayedUnit } from '../graphQl/api_generated';

export const formatPrice = (price: number | string) => {
  let newPrice = typeof price == 'string' ? parseFloat(price as string) : price;

  return currency(newPrice, {
    symbol: '€',
    separator: ' ',
    decimal: ',',
    pattern: '# !',
    negativePattern: '-# !',
  }).format();
};

export const formatFloatTwoDigits = (price: number) => {
  return Math.round((price + Number.EPSILON) * 100) / 100;
};

export const getCartItemPrice = (cartItem: CartItem) => {
  if (
    !cartItem ||
    !cartItem.product ||
    !cartItem.quantity ||
    isNaN(cartItem.product.displayedPrice)
  )
    throw new Error('CartItem has incorrect value');

  /* TODO: There's probably something wrong with the types here */
  if (cartItem.product.displayedMeasureUnit.valueOf() === DisplayedUnit.Unit.valueOf()) {
    return cartItem.quantity * cartItem.product.displayedPrice;
  } else {
    if (!cartItem.product.wholesalePrice) throw new Error('CartItem has incorrect value');
    return (cartItem.product.wholesalePrice / 1000) * cartItem.quantity;
  }
};
