import { selectorFamily, selector } from 'recoil';
import { CartItem } from '../../models/dto';
import { DisplayedUnit, Product } from '../../graphQl/api_generated';
import { getCartItemPrice } from '../../utils/price';
import { cartItemListState } from './cart.store';

export const cartItemSelector = selectorFamily({
  key: 'cartItemSelector',
  get: (i: number) => ({ get }) => get(cartItemListState)[i],
  set: (i: number) => ({ get, set }, replacingItem) => {
    const cartItemList: CartItem[] = [...get(cartItemListState)];
    cartItemList[i] = replacingItem as CartItem;
    set(cartItemListState, cartItemList);
  },
});

export const addCartItemSelector = selector({
  key: 'addCartItemSelector',
  get: (_) => null,
  set: ({ set, get }, defaultProduct) => {
    const cartItemList = get(cartItemListState);
    // FIXME: there's no way this works as expected
    const product = defaultProduct as Product;
    /* TODO: There's probably something wrong with the types here */
    const startingQuantity =
      product.displayedMeasureUnit.valueOf() === DisplayedUnit.Unit.valueOf()
        ? 1
        : product.quantityIncrement;
    set(cartItemListState, [
      ...cartItemList,
      {
        product,
        quantity: startingQuantity,
      },
    ]);
  },
});

export const removeCartItemSelector = selector({
  key: 'removeCartItemSelector',
  get: (_) => null,
  set: ({ set, get }, i) => {
    const cartItemList = get(cartItemListState);
    set(cartItemListState, [...cartItemList.filter((_, index) => i !== index)]);
  },
});

export const cartItemQuantitySelector = selectorFamily({
  key: 'cartItemQuantitySelector',
  get: (i: number | null) => ({ get }) => {
    if (i !== null) {
      const cartItem = get(cartItemSelector(i)) as CartItem;
      if (cartItem) {
        return cartItem.quantity;
      }
    }
    return 0;
  },
  set: (i: number | null) => ({ set, get }, newQuantity) => {
    if (i !== null) {
      if (newQuantity === 0) {
        set(removeCartItemSelector, i);
      } else {
        const cartItem = { ...(get(cartItemSelector(i)) as CartItem) };
        cartItem.quantity = newQuantity as number;
        set(cartItemSelector(i), cartItem);
      }
    }
  },
});

export const cartItemPriceSelector = selectorFamily({
  key: 'cartItemPriceSelector',
  get: (i: number | null) => ({ get }) => {
    if (i !== null) {
      const cartItem = get(cartItemSelector(i)) as CartItem;
      if (cartItem) return getCartItemPrice(cartItem);
    }
    return 0;
  },
});

export const getIndexInCart = selectorFamily({
  key: 'cartItemTotalPriceSelector',
  get: (name: string) => ({ get }) => {
    const index = get(cartItemListState).findIndex((item) => item.product.name === name);

    return index >= 0 ? index : null;
  },
});
