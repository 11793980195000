import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isCategoriesModalDisplayedState } from '../../store/dialog.store';
import {
  ModalContainer,
  CategoryContainer,
  CategoryHeader,
  CategoryBody,
  CategoryTitle,
  CloseContainer,
  ChevronContainer,
  AccordionTitle,
  AccordionSubCategory,
  Dot,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DecorationContainer,
} from './CategoriesModal.style';
import { Category } from '../../models/dto';
import { filterState } from '../../store/filter.store';
import { ModalSlide } from '../../assets/global.style';
import { shopInfoSelector } from '../../store/shop/shop.store';

function CategoriesModal({ categories }: { categories: Category[] }): JSX.Element {
  const [isCategoriesModalDisplayed, setIsCategoriesModalDisplayed] = useRecoilState(
    isCategoriesModalDisplayedState
  );
  const setFilter = useSetRecoilState(filterState);
  const [expanded, setExpanded] = useState('');
  const [shop] = useRecoilState(shopInfoSelector);

  const categoriesDecoration = shop.categoriesDecorationUrl;

  const onCategoryClick = (catName: string) => {
    setExpanded(expanded === catName ? '' : catName);
    setFilter(catName);
  };

  useEffect(() => {
    setExpanded('');
  }, [isCategoriesModalDisplayed]);

  return (
    <ModalContainer
      open={isCategoriesModalDisplayed}
      onClose={() => setIsCategoriesModalDisplayed(false)}>
      <ModalSlide direction="right" in={isCategoriesModalDisplayed} mountOnEnter unmountOnExit>
        <CategoryContainer>
          <CategoryHeader>
            <CategoryTitle>Catégories</CategoryTitle>
            <CloseContainer onClick={() => setIsCategoriesModalDisplayed(false)} />
          </CategoryHeader>

          <CategoryBody>
            {categories.map((category) => (
              <Accordion
                key={`${category.name}`}
                square
                expanded={expanded === category.name}
                onChange={() => onCategoryClick(category.name)}>
                <AccordionSummary
                  expandIcon={<ChevronContainer />}
                  aria-controls={`${category.name}-content`}
                  id={`${category.name}-header`}>
                  <AccordionTitle>{category.name}</AccordionTitle>
                </AccordionSummary>

                <AccordionDetails>
                  {category.subCategories.map((subCategory, index) => (
                    <AccordionSubCategory
                      key={index}
                      onClick={() => {
                        setFilter(subCategory);
                        setIsCategoriesModalDisplayed(false);
                      }}>
                      <Dot />
                      {subCategory}
                    </AccordionSubCategory>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </CategoryBody>

          {!!categoriesDecoration && (
            <DecorationContainer>
              <img src={categoriesDecoration} alt="CategoriesDecoration" />
            </DecorationContainer>
          )}
        </CategoryContainer>
      </ModalSlide>
    </ModalContainer>
  );
}

export default CategoriesModal;
