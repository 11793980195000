import styled from 'styled-components';
import CSS from 'csstype';
import {
  Card,
  CardMedia,
  CardActions,
  CardContent,
  IconButton,
  withStyles,
  makeStyles,
} from '@material-ui/core';

export const CardContainer = withStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    contentVisibility: 'auto',
    minWidth: '167px',
    height: '100%',
    '&.MuiPaper-elevation1': {
      boxShadow: `0px 0px 8px var(--primary)`,
    },
    '&.Selected': {
      boxShadow: `0px 0px 0px 4px var(--primary)`,
    },
    '&.muted': {
      opacity: 0.25,
    },
  },
}))(Card);

export const CardMediaContainer = withStyles(() => ({
  root: {
    display: 'flex',
    height: 140,
    backgroundColor: 'var(--primaryLighter)',
  },
}))(CardMedia);

export const ImageStyles = makeStyles({
  img: {
    backgroundSize: (props: CSS.Properties) => props.backgroundSize,
  },
});

export const CardActionsContainer = styled(CardActions)`
  padding: 0 10px 10px 17px !important;
`;

export const CardPriceContainer = styled(CardContent)`
  padding: 0 10px 10px 17px !important;
`;

export const CardGlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

export const IconButtonContainer = styled(IconButton)`
  padding: 10px !important;
  background-color: var(--primary) !important;
  border-radius: 5px !important;
  margin-left: auto !important;
`;
