import {
  CardContent,
  CardActions,
  CardMedia,
  Button,
  IconButton,
  Typography,
  withStyles,
  InputBase,
} from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as CloseBold } from '../../assets/closeBold.svg';

export const CardContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  border-bottom: 1px solid var(--neutralLight);
  &:last-child {
    border-bottom: 0;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 376px) {
    margin-left: 10px;
  }
`;

export const ReferenceContainer = styled(Typography)`
  position: absolute !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: var(--primaryFontColor);
`;

export const CardMediaContainer = styled(CardMedia)`
  width: 80px;
  height: 100px;
  @media screen and (min-width: 376px) {
    width: 100%;
    max-width: 110px;
  }
`;

export const CardContentContainer = styled(CardContent)`
  display: flex;
  padding: 0 10px !important;
  justify-content: space-between !important;
  width: 100%;
`;

export const TotalContainer = styled(Typography)`
  font-family: Roboto !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 900 !important;
  line-height: 26px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
  color: var(--primaryFontColor);
`;

export const Inf = styled.span`
  font-family: Roboto !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 200 !important;
  line-height: 26px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
`;

export const MarkedPriceContainer = styled(Typography)`
  font-family: Roboto !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 14px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
  min-height: 14px !important;
  color: var(--primaryFontColor);
`;

export const CardActionsContainer = styled(CardActions)`
  padding: 15px 0px 0px 0px !important;
  position: absolute !important;
  margin-top: 46px !important;
`;

export const IconButtonContainer = styled(IconButton)`
  padding: 10px !important;
  border-radius: 5px !important;
  margin-left: auto !important;
`;

export const ButtonQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const ButtonQuantity = styled(Button)`
  padding: 6.5px 10px !important;
  min-width: 25px !important;
  background-color: var(--primaryLighter) !important;
  border-color: var(--primaryLighter) !important;
`;

export const IconDeleteContainer = styled.div`
  padding: 10px;
`;

export const ButtonDelete = styled(IconButton)`
  width: 17px;
  height: 17px;
  padding: 4px !important;
  background-color: var(--primary) !important;

  & svg {
    width: 100%;
  }
`;

export const DeleteFilter = styled(CloseBold)``;

export const BootstrapInput = withStyles((theme) => ({
  root: {
    width: 80,
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'var(--primaryLighter)',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'var(--primaryFont)',
    '&:focus': {
      borderRadius: 4,
      borderColor: 'var(--primaryLighter)',
      backgroundColor: 'var(--primaryLighter)',
      boxShadow: `0 0 0 0.2rem var(--neutralLight)`,
    },
  },
}))(InputBase);
