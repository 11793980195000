import styled from 'styled-components';

export const ShopName = styled.div`
  margin: 20px 0px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const AddressText = styled.div`
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  width: 60%;
`;

export const ShopInfo = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DayRow = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  padding: 11px 20px;
  text-transform: capitalize;
  justify-content: space-between;
  display: flex;
`;

export const Grid = styled.div`
  padding-bottom: 20px;
`;

export const SocialNetworksBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
`;
