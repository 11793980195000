import React from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { Button, ButtonGroup, Select, MenuItem } from '@material-ui/core';
import {
  BootstrapInput,
  ButtonDelete,
  ButtonQuantity,
  ButtonQuantityContainer,
  IconDeleteContainer,
  DeleteFilter,
} from './CatalogItemQuantity.style';
import { DisplayedUnit, Product } from '../../graphQl/api_generated';
import { removeCartItemSelector, cartItemQuantitySelector } from '../../store/cart/cartItem.store';
import { getShortUnit } from '../../utils/units';

function CatalogItemQuantity({
  product,
  indexInCart,
}: {
  product: Product;
  indexInCart: number | null;
}): JSX.Element {
  const removeItemFromCart = useSetRecoilState(removeCartItemSelector);
  const [quantity, setQuantity] = useRecoilState(cartItemQuantitySelector(indexInCart));

  return (
    <ButtonQuantityContainer>
      {/* TODO: There's probably something wrong with the types here */}
      {product.displayedMeasureUnit.valueOf() === DisplayedUnit.Unit.valueOf() ? (
        <ButtonGroup style={{ height: '100%' }}>
          <ButtonQuantity
            style={{ fontFamily: 'var(--primaryFont)', fontWeight: 700, fontSize: 18 }}
            onClick={() => setQuantity((quantity as number) - 1)}>
            -
          </ButtonQuantity>
          <Button
            style={{
              backgroundColor: 'var(--primaryLighter)',
              borderColor: 'var(--primaryLighter)',
              fontFamily: 'var(--primaryFont)',
              fontWeight: 700,
              fontSize: 18,
            }}>
            {quantity as number}
          </Button>
          <ButtonQuantity
            style={{ fontFamily: 'var(--primaryFont)', fontWeight: 700, fontSize: 18 }}
            onClick={() => setQuantity((quantity as number) + 1)}>
            +
          </ButtonQuantity>
        </ButtonGroup>
      ) : (
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={quantity}
          onChange={(event) => setQuantity(event.target.value as number)}
          input={<BootstrapInput />}>
          {[...Array(10)].map((_, index) => (
            <MenuItem key={index} value={product.quantityIncrement * (index + 1)}>
              {product.quantityIncrement * (index + 1)}
              {` ${getShortUnit(product.displayedMeasureUnit)}`}
            </MenuItem>
          ))}
        </Select>
      )}
      <IconDeleteContainer>
        <ButtonDelete onClick={() => removeItemFromCart(indexInCart)}>
          <DeleteFilter />
        </ButtonDelete>
      </IconDeleteContainer>
    </ButtonQuantityContainer>
  );
}

export default CatalogItemQuantity;
