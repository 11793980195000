import { atom, selector } from 'recoil';
import { CartItem } from '../../models/dto';
import { bagsTotalPriceState } from './craftBag.store';
import { cartItemPriceSelector } from './cartItem.store';

export const cartItemListState = atom({
  key: 'cartItemListState',
  default: [] as CartItem[],
});

export const cartTotalPriceState = selector({
  key: 'cartTotalPriceState',
  get: ({ get }) => {
    const cartItemList: CartItem[] = get(cartItemListState);

    return cartItemList.reduce((acc, curr, i) => {
      return acc + (get(cartItemPriceSelector(i)) as number);
    }, 0);
  },
});

export const totalLoyaltyPointsState = selector({
  key: 'totalLoyaltyPointsState',
  get: ({ get }) => {
    const cartItemList: CartItem[] = get(cartItemListState);

    return cartItemList.reduce((acc, curr, i) => {
      return acc + curr.quantity * curr.product.loyaltyPoints;
    }, 0);
  },
});

export const cartTotalPriceWithBagState = selector({
  key: 'cartTotalPriceWithBagState',
  get: ({ get }) => {
    const cartTotalPrice: number = get(cartTotalPriceState);
    const bagsTotalPrice: number = get(bagsTotalPriceState);

    return cartTotalPrice + bagsTotalPrice;
  },
});
