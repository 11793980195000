import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import { Typography, CardContent } from '@material-ui/core';
import {
  CardContainer,
  CardMediaContainer,
  CardActionsContainer,
  IconButtonContainer,
  CardGlobalContainer,
  CardPriceContainer,
  ImageStyles,
} from './CatalogItem.style';
import { ReactComponent as AddToCart } from '../../assets/addToCart.svg';
import { Product, DisplayedUnit } from '../../graphQl/api_generated';
import { addCartItemSelector, getIndexInCart } from '../../store/cart/cartItem.store';
import { formatPrice } from '../../utils/price';
import { getShortUnit } from '../../utils/units';
import placeholderImg from '../../assets/card-img-placeholder.png';
import CatalogItemQuantity from './CatalogItemQuantity';
import { shopInfoSelector } from '../../store/shop/shop.store';
import LoyaltyPoints from '../LoyaltyPoints';

function CatalogItem({ product }: { product: Product }): JSX.Element {
  const addItemToCart = useSetRecoilState(addCartItemSelector);
  const indexInCart: number | null = useRecoilValue(getIndexInCart(product.name));
  const isInCart: boolean = indexInCart !== null;

  const props = { backgroundSize: !!product.pictureUrl ? 'none' : 'cover' };
  const classes = ImageStyles(props);

  /* TODO: There's probably something wrong with the types here */
  const isPriceByUnitDisplayed =
    product.displayedMeasureUnit.valueOf() !== DisplayedUnit.Unit.valueOf();

  const displayedPriceFormatting = () => {
    if (product.displayedMeasureUnit.valueOf() === DisplayedUnit.Unit.valueOf()) return "l'unité";

    const middleWord = product.displayedQuantity === 1 ? 'le' : 'les';
    const quantity = product.displayedQuantity;
    const productUnit = getShortUnit(product.displayedMeasureUnit);

    return `${middleWord} ${quantity} ${productUnit}`;
  };

  const [shop] = useRecoilState(shopInfoSelector);

  const placeholderUrl = shop.placeholderUrl || placeholderImg;

  return (
    <CardContainer
      className={`${isInCart ? 'Selected' : ''} ${product.isAvailable ? '' : 'muted'}`}>
      <CardMediaContainer
        image={product.pictureUrl || placeholderUrl}
        className={classes.img}
        title={product.name}
      />
      <CardGlobalContainer>
        <CardContent>
          <div>
            <Typography
              variant="h6"
              component="h2"
              style={{
                lineHeight: 'inherit',
                fontWeight: 'bold',
                color: 'var(--primaryFont)',
              }}>
              {product.name}
            </Typography>

            {isPriceByUnitDisplayed && (
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
                component="p"
                style={{
                  lineHeight: 'inherit',
                  fontSize: 12,
                  opacity: 0.8,
                  fontFamily: 'var(--primaryFont)',
                  fontWeight: 300,
                }}>
                {formatPrice(product.wholesalePrice)}/{getShortUnit(product.wholesaleMeasureUnit)}
              </Typography>
            )}
          </div>
        </CardContent>
        <div>
          <CardPriceContainer>
            <Typography
              variant="h5"
              color="primary"
              component="p"
              style={{
                lineHeight: 'inherit',
                fontFamily: 'var(--primaryFont)',
                fontWeight: 900,
                fontSize: 25,
              }}>
              {formatPrice(product.displayedPrice)}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={{
                lineHeight: 'inherit',
                fontFamily: 'var(--primaryFont)',
                fontWeight: 300,
                fontSize: 14,
              }}>
              {displayedPriceFormatting()}
            </Typography>
            <LoyaltyPoints points={product.loyaltyPoints} disabled={!product.isAvailable} />
          </CardPriceContainer>
          <CardActionsContainer>
            {isInCart ? (
              <CatalogItemQuantity product={product} indexInCart={indexInCart} />
            ) : (
              <IconButtonContainer
                onClick={() => addItemToCart(product)}
                disabled={!product.isAvailable}>
                <AddToCart />
              </IconButtonContainer>
            )}
          </CardActionsContainer>
        </div>
      </CardGlobalContainer>
    </CardContainer>
  );
}

export default CatalogItem;
