import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  height: 55px;
`;

export const LogoContainer = styled.img`
  height: 55px;
`;

export const ShopInfosBox = styled.div`
  position: absolute;
  left: 20px;
  cursor: pointer;
`;

export const Iicon = styled.div`
  background-color: var(--primaryLighter);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  position: absolute;
  top: 25px;
  right: -10px;
`;

export const HeaderDecorationContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.8;
`;
