import React, { useEffect, useMemo } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

interface HelmetProps {
  name?: string;
  logoUrl?: string;
}

export function Helmet(props: HelmetProps) {
  const { name = '', logoUrl = '' } = props;

  useEffect(() => {
    let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = logoUrl;
  }, [logoUrl]);

  const tabTitle = useMemo(() => (name.length > 0 ? `${name} - ` : ''), [name]);

  return (
    <ReactHelmet>
      <title>{tabTitle}Click & Collect</title>
    </ReactHelmet>
  );
}
