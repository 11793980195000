import config from '../config.json';
import { useRecoilState, useRecoilValue } from 'recoil';
import { shopInfoSelector, theme } from '../store/shop/shop.store';
import { ColorTheme } from '../graphQl/api_generated';

const tenantColors = config.tenantColors;

const DEFAULT_VALUES = {
  primary: tenantColors.primary,
  primaryLighter: tenantColors.primaryLighter,
  contrast: tenantColors.contrast,
  primaryFontColor: tenantColors.primaryFont,

  neutralDarker: '#000000',
  neutral: '#ffffff',
  neutralLight: 'rgba(226, 222, 215, 1)',
  neutralLighter: 'rgba(226, 222, 215, 0.5)',

  error: 'red',

  primaryFont: 'Roboto',
  secondaryFont: 'Lato',
};

const fonts = ['Lato', 'Roboto', 'Playfair Display', 'Oswald'];

export const ThemeComponent = () => {
  const shopTheme = useRecoilValue(theme) as ColorTheme;
  const [shop] = useRecoilState(shopInfoSelector);

  const isPrimaryFontKnown = shop.primaryFont && fonts.includes(shop.primaryFont);
  const isSecondaryFontKnown = shop.secondaryFont && fonts.includes(shop.secondaryFont);

  return (
    <style>
      {`:root {
        --primary: ${shopTheme.primary || DEFAULT_VALUES.primary};
        --primaryLighter: ${shopTheme.primaryLighter || DEFAULT_VALUES.primaryLighter};
        --contrast: ${shopTheme.contrast || DEFAULT_VALUES.contrast};
        --primaryFontColor: ${shopTheme.primaryFontColor || DEFAULT_VALUES.primaryFontColor};

        --neutralDarker: #000000;
        --neutral: #ffffff;
        --neutralLight: rgba(226, 222, 215, 1);
        --neutralLighter: rgba(226, 222, 215, 0.5);

        --error: 'red';

        --primaryFont: ${isPrimaryFontKnown ? shop.primaryFont : DEFAULT_VALUES.primaryFont};
        --secondaryFont: ${
          isSecondaryFontKnown ? shop.secondaryFont : DEFAULT_VALUES.secondaryFont
        };
      }`}
      ;
    </style>
  );
};
