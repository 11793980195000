export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  DateTime: any;
  Price: any;
  UUID: any;
};

export type ColorTheme = {
  __typename?: 'ColorTheme';
  contrast?: Maybe<Scalars['String']>;
  primary?: Maybe<Scalars['String']>;
  primaryFontColor?: Maybe<Scalars['String']>;
  primaryLighter?: Maybe<Scalars['String']>;
};

export enum DisplayedUnit {
  Gramme = 'GRAMME',
  Millilitre = 'MILLILITRE',
  Unit = 'UNIT',
}

export type LoyaltyProgram = {
  __typename?: 'LoyaltyProgram';
  isEnabled: Scalars['Boolean'];
  pointsDescription: Scalars['String'];
  pointsName: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  submitOrder: PayableOrder;
};

export type MutationSubmitOrderArgs = {
  orderCreate: OrderCreate;
};

export type OpenHours = {
  __typename?: 'OpenHours';
  week: Week;
};

export type OrderCreate = {
  amount: Scalars['BigDecimal'];
  consentToLoyaltyProgram: Scalars['Boolean'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  hasBag: Scalars['Boolean'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  products: Array<OrderProductInput>;
  shopId: Scalars['UUID'];
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  createdAt: Scalars['DateTime'];
  name: Scalars['String'];
  orderReference: Scalars['UUID'];
  price: Scalars['Price'];
  productId: Scalars['UUID'];
  quantity: Scalars['Int'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderProductInput = {
  price: Scalars['Price'];
  quantity: Scalars['Int'];
  reference: Scalars['String'];
  unit: DisplayedUnit;
};

export type PaginationProducts = {
  __typename?: 'PaginationProducts';
  maxPages?: Maybe<Scalars['Int']>;
  products: Array<Product>;
};

export type PayableOrder = {
  __typename?: 'PayableOrder';
  amount: Scalars['Price'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  hasBag: Scalars['Boolean'];
  lastname: Scalars['String'];
  paymentSecrets: Scalars['String'];
  phone: Scalars['String'];
  pickupDate: Scalars['DateTime'];
  products: Array<OrderProduct>;
  reference: Scalars['UUID'];
  shopId: Scalars['UUID'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Product = {
  __typename?: 'Product';
  capacity: Scalars['Int'];
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  displayedMeasureUnit: ProductDisplayedUnit;
  displayedPrice: Scalars['Price'];
  displayedQuantity: Scalars['Int'];
  id: Scalars['UUID'];
  isAvailable: Scalars['Boolean'];
  isDraft: Scalars['Boolean'];
  loyaltyPoints: Scalars['Int'];
  name: Scalars['String'];
  needsJar: Scalars['Boolean'];
  pictureUrl?: Maybe<Scalars['String']>;
  quantityIncrement: Scalars['Int'];
  sellingMeasureUnit: WholesaleMeasureUnitProduct;
  sellingPrice: Scalars['Price'];
  shopId: Scalars['UUID'];
  subcategory: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  wholesaleMeasureUnit: WholesaleMeasureUnitProduct;
  wholesalePrice?: Maybe<Scalars['Price']>;
};

export enum ProductDisplayedUnit {
  Gramme = 'GRAMME',
  Millilitre = 'MILLILITRE',
  Unit = 'UNIT',
}

export type Query = {
  __typename?: 'Query';
  getProducts: PaginationProducts;
  getShopInformations: ShopInformations;
};

export type QueryGetProductsArgs = {
  nbPerPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  shopName: Scalars['String'];
};

export type QueryGetShopInformationsArgs = {
  shopName: Scalars['String'];
};

export type ShopInformations = {
  __typename?: 'ShopInformations';
  address: Scalars['String'];
  backgroundDecorationUrl?: Maybe<Scalars['String']>;
  cartDecorationUrl?: Maybe<Scalars['String']>;
  categoriesDecorationUrl?: Maybe<Scalars['String']>;
  defaultCurrency: Scalars['String'];
  email: Scalars['String'];
  facebookLink?: Maybe<Scalars['String']>;
  headerDecorationUrl?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  instagramLink?: Maybe<Scalars['String']>;
  kraftBagsPrice: Scalars['BigDecimal'];
  logoUrl?: Maybe<Scalars['String']>;
  loyaltyProgram?: Maybe<LoyaltyProgram>;
  name: Scalars['String'];
  openHours: OpenHours;
  orderSummaryDecorationUrl?: Maybe<Scalars['String']>;
  ownerId: Scalars['UUID'];
  phone: Scalars['String'];
  pickupDelayMinutes: Scalars['Int'];
  pickupHours: OpenHours;
  pickupTimeIncrementMinutes: Scalars['Int'];
  placeholderUrl?: Maybe<Scalars['String']>;
  primaryFont?: Maybe<Scalars['String']>;
  secondaryFont?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  theme: ColorTheme;
  twitterLink?: Maybe<Scalars['String']>;
  validationDecorationUrl?: Maybe<Scalars['String']>;
};

export type Week = {
  __typename?: 'Week';
  friday: Array<WorkingHours>;
  monday: Array<WorkingHours>;
  saturday: Array<WorkingHours>;
  sunday: Array<WorkingHours>;
  thursday: Array<WorkingHours>;
  tuesday: Array<WorkingHours>;
  wednesday: Array<WorkingHours>;
};

export enum WholesaleMeasureUnitProduct {
  Kilogramme = 'KILOGRAMME',
  Litre = 'LITRE',
  Unit = 'UNIT',
}

export type WorkingHours = {
  __typename?: 'WorkingHours';
  close: Scalars['DateTime'];
  open: Scalars['DateTime'];
};
