import React from 'react';
import { useRecoilState } from 'recoil';
import {
  DialogContainer,
  ButtonContainer,
  TextContainer,
  ImageContainer,
} from './ResponseStatus.style';
import { isResponseStatusDialogDisplayedState } from '../../store/dialog.store';
import { shopInfoSelector } from '../../store/shop/shop.store';

export enum ResponseStatus {
  PENDING,
  SUCCESS,
  ERROR,
}

function ResponseStatusComponent({
  responseStatusState,
  errorFetch,
}: {
  responseStatusState: [ResponseStatus, React.Dispatch<React.SetStateAction<ResponseStatus>>];
  errorFetch: boolean;
}): JSX.Element {
  const [isResponseStatusDialogDisplayed, setIsResponseStatusDialogDisplayed] = useRecoilState(
    isResponseStatusDialogDisplayedState
  );
  const [shop] = useRecoilState(shopInfoSelector);

  const image = shop.orderSummaryDecorationUrl;

  const [responseStatus, setResponseStatus] = responseStatusState;

  return (
    <DialogContainer
      onClose={() => setIsResponseStatusDialogDisplayed(false)}
      aria-labelledby="simple-dialog-title"
      open={isResponseStatusDialogDisplayed}
      style={{ margin: 0, borderRadius: 10 }}>
      {errorFetch ? (
        <TextContainer>
          Une erreur est survenue lors du chargement des produits.
          <br />
          Merci de bien vouloir réessayer plus tard ou de contacter directement le commercant.
        </TextContainer>
      ) : responseStatus === ResponseStatus.SUCCESS ? (
        <TextContainer>
          Merci d’avoir effectué votre commande chez nous.
          <br />
          Vous allez recevoir un e-mail de récapitulatif de votre commande
        </TextContainer>
      ) : responseStatus === ResponseStatus.ERROR ? (
        <TextContainer>
          Impossible d'effectuer la commande.
          <br />
          Une erreur s'est produite.
        </TextContainer>
      ) : (
        <TextContainer>Envoi de votre commande en cours.</TextContainer>
      )}
      <ButtonContainer
        variant="contained"
        onClick={() => {
          setIsResponseStatusDialogDisplayed(false);
          setResponseStatus(ResponseStatus.PENDING);
        }}>
        Revenir au site
      </ButtonContainer>
      {!!image && (
        <ImageContainer>
          <img style={{ width: 123 }} src={image} alt="decorationImage" />
        </ImageContainer>
      )}
    </DialogContainer>
  );
}

export default ResponseStatusComponent;
