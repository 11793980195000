import React from 'react';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { ButtonGroup, Button, Select, MenuItem } from '@material-ui/core';
import {
  BootstrapInput,
  CardContainer,
  LeftContainer,
  Inf,
  MarkedPriceContainer,
  TotalContainer,
  RightContainer,
  CardMediaContainer,
  CardContentContainer,
  CardActionsContainer,
  ButtonQuantityContainer,
  ButtonQuantity,
  IconDeleteContainer,
  ButtonDelete,
  DeleteFilter,
  ReferenceContainer,
} from './CartItem.style';
import { CartItem as CartItemType } from '../../models/dto';
import { DisplayedUnit } from '../../graphQl/api_generated';
import {
  removeCartItemSelector,
  cartItemQuantitySelector,
  cartItemPriceSelector,
} from '../../store/cart/cartItem.store';
import { formatPrice } from '../../utils/price';
import { getShortUnit } from '../../utils/units';
import { shopInfoSelector } from '../../store/shop/shop.store';

function CartItem({
  cartItem,
  indexInCart,
}: {
  cartItem: CartItemType;
  indexInCart: number;
}): JSX.Element {
  const removeItemFromCart = useSetRecoilState(removeCartItemSelector);
  const productPrice = formatPrice(useRecoilValue(cartItemPriceSelector(indexInCart)));
  const [quantity, setQuantity] = useRecoilState(cartItemQuantitySelector(indexInCart));

  // TODO: There's probably something wrong with both enums here.
  const isPriceByUnitDisplayed =
    cartItem.product.displayedMeasureUnit.toString() !== DisplayedUnit.Unit.toString();

  const [shop] = useRecoilState(shopInfoSelector);

  const productImage = cartItem.product.pictureUrl || shop.placeholderUrl || undefined;

  return (
    <CardContainer>
      <CardMediaContainer image={productImage} title={cartItem.product.name} />
      <CardContentContainer>
        <LeftContainer>
          <ReferenceContainer>{cartItem.product.name}</ReferenceContainer>
          <CardActionsContainer>
            <ButtonQuantityContainer>
              {/* TODO: There's probably something wrong with the types here */}
              {cartItem.product.displayedMeasureUnit.valueOf() === DisplayedUnit.Unit.valueOf() ? (
                <ButtonGroup style={{ height: '100%' }}>
                  <ButtonQuantity
                    style={{
                      fontFamily: 'var(--primaryFont)',
                      fontWeight: 700,
                      fontSize: 18,
                    }}
                    onClick={() => {
                      setQuantity((quantity as number) - 1);
                    }}>
                    -
                  </ButtonQuantity>
                  <Button
                    style={{
                      backgroundColor: 'var(--primaryLighter)',
                      borderColor: 'var(--primaryLighter)',
                      fontFamily: 'var(--primaryFont)',
                      fontWeight: 700,
                      fontSize: 18,
                    }}>
                    {quantity as number}
                  </Button>
                  <ButtonQuantity
                    style={{ fontFamily: 'var(--primaryFont)', fontWeight: 700, fontSize: 18 }}
                    onClick={() => setQuantity((quantity as number) + 1)}>
                    +
                  </ButtonQuantity>
                </ButtonGroup>
              ) : (
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={quantity}
                  onChange={(event) => setQuantity(event.target.value as number)}
                  input={<BootstrapInput />}>
                  {[...Array(10)].map((_, index) => (
                    <MenuItem key={index} value={cartItem.product.quantityIncrement * (index + 1)}>
                      {cartItem.product.quantityIncrement * (index + 1)}
                      {` ${getShortUnit(cartItem.product.displayedMeasureUnit)}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <IconDeleteContainer>
                <ButtonDelete onClick={() => removeItemFromCart(indexInCart)}>
                  <DeleteFilter />
                </ButtonDelete>
              </IconDeleteContainer>
            </ButtonQuantityContainer>
          </CardActionsContainer>
        </LeftContainer>
        <RightContainer>
          <TotalContainer>
            {productPrice}
            {cartItem.product.displayedMeasureUnit.valueOf() !== DisplayedUnit.Unit.valueOf() && (
              <Inf>*</Inf>
            )}
          </TotalContainer>
          <MarkedPriceContainer>
            {isPriceByUnitDisplayed &&
              `${formatPrice(cartItem.product.wholesalePrice)}/ ${getShortUnit(
                cartItem.product.wholesaleMeasureUnit
              )}`}
          </MarkedPriceContainer>
        </RightContainer>
      </CardContentContainer>
    </CardContainer>
  );
}

export default CartItem;
