import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import CatalogItem from './CatalogItem';
import { Product } from '../../graphQl/api_generated';
import {
  DeleteFilter,
  IconButtonContainer,
  BackgroundDecorationContainer,
  CatalogBox,
} from './Catalog.style';
import { filterState } from '../../store/filter.store';
import { shopInfoSelector } from '../../store/shop/shop.store';

function Catalog({ products }: { products: Product[] }): JSX.Element {
  const [filter, setFilter] = useRecoilState(filterState);

  const filterByCategory = (product: Product) => {
    if (filter !== '') {
      return (
        product.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        product.subcategory.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        product.category.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      );
    }
    return true;
  };

  const [shop] = useRecoilState(shopInfoSelector);

  const backgroundDecoration = shop.backgroundDecorationUrl;

  return (
    <CatalogBox>
      {filter ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 12,
            overflow: 'hidden',
          }}>
          <Typography>{filter}</Typography>
          <IconButtonContainer onClick={() => setFilter('')} style={{ padding: 0 }}>
            <DeleteFilter />
          </IconButtonContainer>
        </div>
      ) : (
        ''
      )}
      <Grid container spacing={2}>
        {products.filter(filterByCategory).map((product, index) => (
          <Grid key={`products-${index}`} item xs={6} sm={4} md={3} lg={2}>
            <CatalogItem product={product} />
          </Grid>
        ))}
      </Grid>
      {!!backgroundDecoration && (
        <BackgroundDecorationContainer>
          <img src={backgroundDecoration} alt="Background Decoration" />
        </BackgroundDecorationContainer>
      )}
    </CatalogBox>
  );
}

export default Catalog;
