import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { ReactComponent as CloseLight } from '../../assets/closeLight.svg';

export const CartContainer = styled.div`
  z-index: 1;
  top: 0;
  overflow: hidden;
  right: 0;
  position: absolute;
  width: 315px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  background-color: var(--contrast);
  @media screen and (min-width: 376px) {
    width: 365px;
  }
`;

export const CartBody = styled.div`
  padding: 20px;
`;

export const CartProducts = styled.div`
  width: 100%;
`;

export const CartLegals = styled.div``;

export const LegalsPriceText = styled.p`
  margin-bottom: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primaryFontColor);
`;

export const LegalBagCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

export const LegalsBagText = styled.div`
  margin-bottom: 15px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primaryFontColor);
`;

export const Dot = styled.span`
  height: 5px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--primaryFontColor);
  margin-right: 10px;
  display: inline-block;
`;

export const CartSummary = styled.div`
  padding: 20px;
  background-color: var(--neutralLighter);
  bottom: 0;
`;

export const SummaryContainer = styled.div`
  border-bottom: 1px solid var(--neutralLight);
`;

export const SummaryLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:nth-child(1) {
    margin-top: 20px;
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const SummaryTitle = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primaryFontColor);
`;

export const SummaryData = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: var(--neutralDarker);
`;

export const CartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: var(--neutralLighter);
`;

export const CartTitle = styled.span`
  margin-left: 20px;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  color: var(--primaryFontColor);
  text-align: left;
`;

export const CartSubTitle = styled.span`
  font-size: 20px;
  color: var(--primaryFontColor);
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
`;

export const CloseContainer = styled(CloseLight)`
  width: 19px;
  height: 20px;
  margin: 0 20px 40px 0;
  cursor: pointer;
`;

export const QuantityContainer = styled.div`
  padding: 10px !important;
  background-color: var(--primary) !important;
  border-radius: 5px !important;
  margin-left: auto !important;
`;

export const ValidationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
`;

export const ValidationTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ValidationPrice = styled.div`
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  z-index: 1;
  letter-spacing: 0em;
  text-align: right;
`;

export const ButtonContainer = styled(Button)`
  width: 100% !important;
  height: 60px !important;
  margin-top: 20px !important;
  background: var(--primary) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  z-index: 1;
  & .MuiButton-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: none;
  }
`;

export const CartDecorationContainer = styled.div`
  z-index: -1;
  position: absolute;
  bottom: -5px;
  opacity: 0.8;
  right: 0;
`;
