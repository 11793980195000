import styled from 'styled-components';

export const LogoBox = styled.div`
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 20vh;
  left: 0;
  right: 0;

  margin: auto;
`;
