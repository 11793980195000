import React, { useEffect } from 'react';
import { DialogTitle, Select, MenuItem, InputLabel, Checkbox } from '@material-ui/core';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';
import { useRecoilValue, useResetRecoilState, useSetRecoilState, useRecoilState } from 'recoil';
import {
  CloseContainer,
  TextFieldWrapper,
  TextFieldContainer,
  DialogContainer,
  VerificationContainer,
  TitleContainer,
  BottomContainer,
  DatePickerContainer,
  InformationContainer,
  CartSummary,
  SummaryContainer,
  SummaryLine,
  SummaryTitle,
  SummaryData,
  ValidationContainer,
  ValidationTitle,
  ValidationPrice,
  ButtonContainer,
  TopContainer,
  RecapDecorationContainer,
  FormWrapper,
} from './ValidationDialog.style';
import { ResponseStatus } from '../ResponseStatus/ResponseStatus';
import {
  cartTotalPriceWithBagState,
  cartTotalPriceState,
  totalLoyaltyPointsState,
  cartItemListState,
} from '../../store/cart/cart.store';
import {
  craftBagNotNeededState,
  bagsNumberState,
  bagsTotalPriceState,
} from '../../store/cart/craftBag.store';
import {
  isValidationDialogDisplayedState,
  isCartModalDisplayedState,
  isResponseStatusDialogDisplayedState,
} from '../../store/dialog.store';
import { formatPrice } from '../../utils/price';
import {
  orderLastname,
  orderFirstname,
  orderEmail,
  orderPhone,
  orderPickupDate,
  orderConsentToLoyaltyProgram,
} from '../../store/orderForm.store';
import { formatOrder } from './SubmitCart';
import { useMutation } from '@apollo/client';
import { CREATE_ORDER } from '../../graphQl/GQLExports';
import {
  // useIsOpenForTheDay,
  // useIsAvailableForPickup,
  useGeneratePickUpOptions,
  useIsOpen,
  useGetNextAvailableDay,
} from './shopFunctions';
import { availableDayFromWeek, closedDays, shopInfoSelector } from '../../store/shop/shop.store';
import { LegalBagCheckbox, LegalsBagText, LegalsPriceText } from '../CartModal/CartModal.style';

moment.locale('fr');

function useGenerateSelectOptions(day = new Date()): JSX.Element[] {
  const pickupOptions = useGeneratePickUpOptions(day);

  return pickupOptions.map((option, index) => (
    <MenuItem key={index} value={option.toString()}>
      {moment(option).format('H:mm')}
    </MenuItem>
  ));
}

function ValidationDialog({
  responseStatusState,
}: {
  responseStatusState: [ResponseStatus, React.Dispatch<React.SetStateAction<ResponseStatus>>];
}): JSX.Element {
  const [isValidationDialogDisplayed, setIsValidationDialogDisplayed] = useRecoilState(
    isValidationDialogDisplayedState
  );
  const setIsCartModalDisplayed = useSetRecoilState(isCartModalDisplayedState);
  const setIsResponseStatusDialogDisplayed = useSetRecoilState(
    isResponseStatusDialogDisplayedState
  );

  const [, setResponseStatus] = responseStatusState;

  const [lastname, setLastname] = useRecoilState(orderLastname);
  const [firstname, setFirstname] = useRecoilState(orderFirstname);
  const [email, setEmail] = useRecoilState(orderEmail);
  const [phone, setPhone] = useRecoilState(orderPhone);
  const [pickupDate, setPickupDate] = useRecoilState(orderPickupDate);
  const [consentToLoyaltyProgram, setConsentToLoyaltyProgram] = useRecoilState(
    orderConsentToLoyaltyProgram
  );
  const resetPickupDate = useResetRecoilState(orderPickupDate);

  const cartItemList = useRecoilValue(cartItemListState);
  const resetCartItemList = useResetRecoilState(cartItemListState);
  const totalPrice = formatPrice(useRecoilValue(cartTotalPriceState));
  const totalLoyaltyPoints = useRecoilValue(totalLoyaltyPointsState);
  const craftBagNotNeeded = useRecoilValue(craftBagNotNeededState);
  const bagsNumber = useRecoilValue(bagsNumberState);
  const bagsTotalPrice = formatPrice(useRecoilValue(bagsTotalPriceState));
  const totalPriceWithBag = useRecoilValue(cartTotalPriceWithBagState);
  const [shop] = useRecoilState(shopInfoSelector);

  const recapDecoration = shop.orderSummaryDecorationUrl;

  const [createOrder] = useMutation(CREATE_ORDER, {
    onError: () => {
      setIsResponseStatusDialogDisplayed(true);
      setResponseStatus(ResponseStatus.ERROR);
    },
    onCompleted: () => {
      setIsResponseStatusDialogDisplayed(true);
      setIsValidationDialogDisplayed(false);
      setIsCartModalDisplayed(false);
      resetCartItemList();
      resetPickupDate();
      setResponseStatus(ResponseStatus.SUCCESS);
    },
  });

  // const isOpenForTheDay = useIsOpenForTheDay(pickupDate);
  // const isAvailableForPickup = useIsAvailableForPickup(pickupDate);
  const getNextAvailableDay = useGetNextAvailableDay();

  useEffect(() => {
    if (getNextAvailableDay) {
      setPickupDate(getNextAvailableDay);
    }
  }, [getNextAvailableDay, setPickupDate]);

  const handleSubmission = () => {
    const order = formatOrder({
      shopId: shop.id,
      lastname,
      firstname,
      email,
      phone,
      amount: totalPriceWithBag,
      hasBag: craftBagNotNeeded,
      pickupDate,
      products: cartItemList,
      consentToLoyaltyProgram,
    });

    setResponseStatus(ResponseStatus.PENDING);

    createOrder({ variables: { order } });
  };

  const handleTimeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newDate = event.target.value as string | undefined | null;

    if (!newDate) return;

    if (!Number.isNaN(Date.parse(newDate))) {
      const selectedPickupDate = new Date(Date.parse(newDate));

      setPickupDate(selectedPickupDate);
    }
  };

  const shouldDateBeAvailable = useRecoilValue(availableDayFromWeek);
  const closedWeekDays = useRecoilValue(closedDays);

  const isOpen = useIsOpen(pickupDate);

  const canSubmit = (): boolean =>
    !lastname || !firstname || !email || !phone || !pickupDate || !isOpen;

  return (
    <DialogContainer
      onClose={() => setIsValidationDialogDisplayed(false)}
      aria-labelledby="simple-dialog-title"
      open={isValidationDialogDisplayed}
      style={{ margin: 0 }}>
      <TopContainer>
        <TitleContainer>
          <DialogTitle>Récapitulatif commande</DialogTitle>
          <CloseContainer onClick={() => setIsValidationDialogDisplayed(false)} />
        </TitleContainer>
        <VerificationContainer>
          Veuillez vérifier si vos informations sont bonnes avant de valider votre commande
        </VerificationContainer>
        <InformationContainer>
          <TextFieldContainer
            label="Nom"
            value={lastname}
            onChange={(event: any) => setLastname(event.target.value)}
          />
          <TextFieldContainer
            label="Prénom"
            value={firstname}
            onChange={(event: any) => setFirstname(event.target.value)}
          />
          <TextFieldContainer
            label="Email"
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
          />
          <TextFieldContainer
            label="Téléphone"
            value={phone}
            onChange={(event: any) => setPhone(event.target.value)}
          />
          <TextFieldWrapper>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'fr'}>
              <DatePickerContainer
                value={pickupDate}
                label="Date de retrait"
                okLabel="Ok"
                cancelLabel="Annuler"
                disablePast
                shouldDisableDate={(date) => {
                  if (!date) return false;
                  if (date.isAfter(moment().add(2, 'months'))) return true;

                  const today = moment(Date.now()).format('MM/DD/YYYY');
                  const currentDate = moment(date).format('MM/DD/YYYY');

                  const currentDay = Intl.DateTimeFormat('en-US', { weekday: 'long' })
                    .format(date?.toDate())
                    .toLocaleLowerCase();

                  const canPickupForTheDate = shouldDateBeAvailable[currentDay];
                  const shouldBeClosed = !closedWeekDays[currentDay];

                  const isDateToday = moment(today).isSame(currentDate);

                  return isDateToday ? !canPickupForTheDate : shouldBeClosed;
                }}
                onChange={(date) => {
                  if (!date) return;

                  setPickupDate(date.toDate());
                }}
                style={{ width: '45%' }}
              />

              <FormWrapper>
                <InputLabel id="demo-simple-select-label">Heure de retrait</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pickupDate}
                  onChange={handleTimeChange}>
                  {useGenerateSelectOptions(pickupDate)}
                </Select>
              </FormWrapper>
            </MuiPickersUtilsProvider>
          </TextFieldWrapper>
        </InformationContainer>
      </TopContainer>
      <BottomContainer>
        <CartSummary>
          <SummaryContainer>
            <SummaryLine>
              <SummaryTitle>Nombre de produits</SummaryTitle>
              <SummaryData>{cartItemList.length}</SummaryData>
            </SummaryLine>

            <SummaryLine>
              <SummaryTitle>Prix des produits</SummaryTitle>
              <SummaryData>{totalPrice}</SummaryData>
            </SummaryLine>

            {shop.loyaltyProgram?.isEnabled && (
              <SummaryLine>
                <SummaryTitle>{shop.loyaltyProgram?.pointsName}</SummaryTitle>
                <SummaryData>{totalLoyaltyPoints}</SummaryData>
              </SummaryLine>
            )}

            <SummaryLine>
              <SummaryTitle>Nombre de sac kraft</SummaryTitle>
              <SummaryData>{bagsNumber}</SummaryData>
            </SummaryLine>

            <SummaryLine>
              <SummaryTitle>Prix des sacs kraft</SummaryTitle>
              <SummaryData>{bagsTotalPrice}</SummaryData>
            </SummaryLine>

            <SummaryLine>
              <SummaryTitle>Livraison</SummaryTitle>
              <SummaryData>
                <i>retrait en boutique gratuit</i>
              </SummaryData>
            </SummaryLine>

            {shop.loyaltyProgram?.isEnabled && (
              <>
                <LegalBagCheckbox>
                  <LegalsBagText style={{ marginBottom: 0 }}>
                    Je souhaite adhérer au programme fidélité {shop.loyaltyProgram?.pointsName}
                  </LegalsBagText>
                  <Checkbox
                    onChange={() => setConsentToLoyaltyProgram(!consentToLoyaltyProgram)}
                    checked={consentToLoyaltyProgram}
                    style={{ padding: 5, color: 'green' }}
                  />
                </LegalBagCheckbox>

                <LegalsPriceText>{shop.loyaltyProgram?.pointsDescription}</LegalsPriceText>
              </>
            )}
          </SummaryContainer>

          <ValidationContainer>
            <ValidationTitle>Prix total</ValidationTitle>
            <ValidationPrice>{formatPrice(totalPriceWithBag)}</ValidationPrice>
          </ValidationContainer>

          <ButtonContainer
            variant="contained"
            onClick={() => handleSubmission()}
            disabled={canSubmit()}>
            Confirmer ma commande
          </ButtonContainer>
          {!!recapDecoration && (
            <RecapDecorationContainer>
              <img style={{ width: 116 }} src={recapDecoration} alt="recap decoration" />
            </RecapDecorationContainer>
          )}
        </CartSummary>
      </BottomContainer>
    </DialogContainer>
  );
}

export default ValidationDialog;
