import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Modal, Checkbox } from '@material-ui/core';
import {
  Dot,
  LegalBagCheckbox,
  ValidationContainer,
  ButtonContainer,
  ValidationPrice,
  ValidationTitle,
  SummaryContainer,
  SummaryLine,
  SummaryTitle,
  SummaryData,
  CartContainer,
  CartHeader,
  LegalsPriceText,
  LegalsBagText,
  CartTitle,
  CloseContainer,
  CartBody,
  CartSubTitle,
  CartProducts,
  CartLegals,
  CartSummary,
  CartDecorationContainer,
} from './CartModal.style';
import CartItem from './CartItem';

import {
  cartItemListState,
  cartTotalPriceState,
  totalLoyaltyPointsState,
  cartTotalPriceWithBagState,
} from '../../store/cart/cart.store';
import {
  craftBagNotNeededState,
  bagsNumberState,
  bagsTotalPriceState,
} from '../../store/cart/craftBag.store';
import {
  isCartModalDisplayedState,
  isValidationDialogDisplayedState,
} from '../../store/dialog.store';
import { formatPrice } from '../../utils/price';
import { ModalSlide } from '../../assets/global.style';
import { bagPrice } from '../../config.json';
import { ProductDisplayedUnit } from '../../graphQl/api_generated';
import { shopInfoSelector } from '../../store/shop/shop.store';

function CartModal(): JSX.Element {
  const setIsValidationDialogDisplayed = useSetRecoilState(isValidationDialogDisplayedState);
  const [isCartModalDisplayed, setIsCartModalDisplayed] = useRecoilState(isCartModalDisplayedState);
  const [triggerTransition, setTriggerTransition] = useState(false);
  const cartItemList = useRecoilValue(cartItemListState);
  const totalPrice = formatPrice(useRecoilValue(cartTotalPriceState));
  const totalLoyaltyPoints = useRecoilValue(totalLoyaltyPointsState);
  const bagsNumber = useRecoilValue(bagsNumberState);
  const bagsTotalPrice = formatPrice(useRecoilValue(bagsTotalPriceState));
  const totalPriceWithBag = formatPrice(useRecoilValue(cartTotalPriceWithBagState));
  const [craftBagNotNeeded, setCraftBagNotNeeded] = useRecoilState(craftBagNotNeededState);

  useEffect(() => {
    // Update the document title using the browser API
    setTimeout(() => {
      setTriggerTransition(isCartModalDisplayed);
    }, 0);
  }, [isCartModalDisplayed]);

  const hasLiterProductInCart: boolean = cartItemList.some(
    (item) => item.product.displayedMeasureUnit === ProductDisplayedUnit.Millilitre
  );
  const [shop] = useRecoilState(shopInfoSelector);

  const kraftBagPrice = parseFloat(shop.kraftBagsPrice)
    ? parseFloat(shop.kraftBagsPrice).toFixed(2)
    : bagPrice;

  const cartDecoration = shop.cartDecorationUrl;

  return (
    <Modal
      open={isCartModalDisplayed}
      onClose={() => setIsCartModalDisplayed(false)}
      style={{ overflowY: 'scroll' }}>
      <ModalSlide direction="left" in={triggerTransition}>
        <CartContainer>
          <CartHeader>
            <CartTitle>Mon panier</CartTitle>
            <CloseContainer onClick={() => setIsCartModalDisplayed(false)} />
          </CartHeader>
          <CartBody>
            <CartSubTitle>Détails produits</CartSubTitle>
            <CartProducts>
              {cartItemList.map((cartItem, index) => (
                <CartItem key={index} indexInCart={index} cartItem={cartItem} />
              ))}
            </CartProducts>
            <CartLegals>
              {hasLiterProductInCart && (
                <LegalsBagText>
                  *Un ou plusieurs des produits sélectionnés nécessitent un contenant consigné.
                  Pensez à rapporter vos contenants vides si vous en avez pour récupérer la consigne
                  !
                </LegalsBagText>
              )}
              <LegalsPriceText>
                *La quantité réelle est susceptible de varier légèrement par rapport aux quantités
                sélectionnées. Nous faisons notre maximum pour rester au plus proche :)
              </LegalsPriceText>
              {!!kraftBagPrice && (
                <LegalsBagText>
                  Les produits en vrac sont chacun embalés dans un sac craft par produit, cela
                  engendrera un coût supplémentaire de {kraftBagPrice}€ par sac.
                </LegalsBagText>
              )}

              <LegalBagCheckbox>
                <Dot />
                <LegalsBagText style={{ marginBottom: 0 }}>
                  J’ai déjà déposé mes bocaux en boutique et je ne souhaite pas de sac kraft.
                </LegalsBagText>
                <Checkbox
                  onChange={() => setCraftBagNotNeeded(!craftBagNotNeeded)}
                  checked={craftBagNotNeeded}
                  style={{ padding: 5, color: 'green' }}
                />
              </LegalBagCheckbox>
            </CartLegals>
          </CartBody>
          <CartSummary>
            <CartSubTitle>Récapitulatif</CartSubTitle>
            <SummaryContainer>
              <SummaryLine>
                <SummaryTitle>Nombre de produits</SummaryTitle>
                <SummaryData>{cartItemList.length}</SummaryData>
              </SummaryLine>

              <SummaryLine>
                <SummaryTitle>Prix des produits</SummaryTitle>
                <SummaryData>{totalPrice}</SummaryData>
              </SummaryLine>

              {shop.loyaltyProgram?.isEnabled && (
                <SummaryLine>
                  <SummaryTitle>{shop.loyaltyProgram?.pointsName}</SummaryTitle>
                  <SummaryData>{totalLoyaltyPoints}</SummaryData>
                </SummaryLine>
              )}

              <SummaryLine>
                <SummaryTitle>Nombre de sac kraft</SummaryTitle>
                <SummaryData>{bagsNumber}</SummaryData>
              </SummaryLine>

              <SummaryLine>
                <SummaryTitle>Prix des sacs kraft</SummaryTitle>
                <SummaryData>{bagsTotalPrice}</SummaryData>
              </SummaryLine>

              <SummaryLine>
                <SummaryTitle>Livraison</SummaryTitle>
                <SummaryData style={{ fontWeight: 300 }}>
                  <i>retrait en boutique gratuit</i>
                </SummaryData>
              </SummaryLine>
            </SummaryContainer>

            <ValidationContainer>
              <ValidationTitle>Prix total</ValidationTitle>
              <ValidationPrice>{totalPriceWithBag}</ValidationPrice>
            </ValidationContainer>

            <ButtonContainer
              variant="contained"
              disabled={cartItemList.length <= 0}
              onClick={() => setIsValidationDialogDisplayed(true)}>
              Valider mon panier
            </ButtonContainer>
            {!!cartDecoration && (
              <CartDecorationContainer>
                <img src={cartDecoration} alt="cart decoration" />
              </CartDecorationContainer>
            )}
          </CartSummary>
        </CartContainer>
      </ModalSlide>
    </Modal>
  );
}

export default CartModal;
