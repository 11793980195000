import { Button, Dialog, withStyles } from '@material-ui/core';
import styled from 'styled-components';

export const DialogContainer = withStyles({
  root: {
    '& .MuiDialog-paper': {
      width: '316px',
      margin: '29px',
      padding: '0',
      overflow: 'hidden',
    },
    '& .MuiDialogTitle-root': {
      padding: '0',
    },
    '& .MuiTypography-root': {
      fontFamily: 'var(--secondaryFont)',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
  },
})(Dialog);

export const ButtonContainer = styled(Button)`
  width: 280px !important;
  height: 60px !important;
  margin: 0 20px 40px 20px !important;
  background: var(--primary) !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  z-index: 1;

  & .MuiButton-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: none;
  }
`;

export const TextContainer = styled.div`
  padding: 40px 20px;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  z-index: 1;
`;

export const ImageContainer = styled.div`
  position: absolute;
  bottom: -4px;
  left: 0;
  opacity: 0.8;
`;
