import styled from 'styled-components';

export const Button = styled.button`
  all: unset;
  text-decoration: underline;
  font-weight: 300;
  margin-top: 0.5rem;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const LoyaltyDescriptionModal = styled.div`
  background: var(--primaryFontColor);
  position: absolute;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin: 1rem;
  z-index: 99;
  color: white;
  font-family: var(--primaryFont);
  max-width: 30rem;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
`;
