import { openDays } from './../../models/shop/dto';
import { atom, selectorFamily, selector } from 'recoil';
import { ColorTheme, ShopInformations, Week, WorkingHours } from '../../graphQl/api_generated';
import moment from 'moment';

const shopInfo = atom<ShopInformations>({
  key: 'shopInfo',
  default: {} as ShopInformations,
  dangerouslyAllowMutability: true, // TODO: Remove this and replace moment with date-fns so we can tests properly
});

export const theme = atom<ColorTheme>({
  key: 'shopTheme',
  default: {} as ColorTheme,
  dangerouslyAllowMutability: true, // TODO: Remove this and replace moment with date-fns so we can tests properly
});

export const pickupInterval = atom<moment.Duration>({
  key: 'pickupInterval',
  default: {} as moment.Duration,
  dangerouslyAllowMutability: true, // TODO: Remove this and replace moment with date-fns so we can tests properly
});

export const minimalDelayUntilPickup = atom<moment.Duration>({
  key: 'minimalDelayUntilPickup',
  default: {} as moment.Duration,
  dangerouslyAllowMutability: true, // TODO: Remove this and replace moment with date-fns so we can tests properly
});

export const pickupHours = atom<Week>({
  key: 'pickupHours',
  default: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  } as Week,
  dangerouslyAllowMutability: true, // TODO: Remove this and replace moment with date-fns so we can tests properly
});

export const shopInfoSelector = selector<ShopInformations>({
  key: 'shopInfoSelector',
  get: ({ get }): ShopInformations => get(shopInfo),
  set: ({ set }, shopData) => {
    const shopInformation = shopData as ShopInformations;

    if (!shopInformation.theme || Object.values(shopInformation.theme).find((prop) => !prop)) {
      console.error('No colors are defined on shop, using default values');
      set(shopInfo, shopInformation);
      return;
    }

    const colors = { ...shopInformation.theme } as {
      primary: string;
      primaryLighter: string;
      contrast: string;
      primaryFont: string;
    };

    const {
      pickupHours: { week: weekPickupHours },
      pickupTimeIncrementMinutes,
      pickupDelayMinutes,
    } = shopInformation;

    set(pickupHours, weekPickupHours);
    set(
      pickupInterval,
      moment.duration({
        minutes: pickupTimeIncrementMinutes === 0 ? 15 : pickupTimeIncrementMinutes,
      })
    );
    set(minimalDelayUntilPickup, moment.duration({ minutes: pickupDelayMinutes }));
    set(theme, colors);

    set(shopInfo, shopInformation);
  },
});

export const isOpen = selectorFamily({
  key: 'isOpen',
  get: (utcDateString: string) => ({ get }): boolean => {
    // returns true if time is within a shop open range
    // Une date est valide si elle est suppérieure a la date minimale de pickup et qu'elle est entre les heures d'ouverture et de fermeture du magasin
    // ET si le magasin est ouvert le jour séléctionné
    let isInWorkingRange = false;

    const workingRange = get(getOpenTimeSlotsForDate(utcDateString));

    // Si le magasin n'a pas d'horraire d'ouverture ou si la date est passée on renvoit false
    if (workingRange.length === 0) return isInWorkingRange;

    isInWorkingRange = workingRange.some((workingRange) => {
      const { open, close } = workingRange;
      const currentDate = new Date(Date.parse(utcDateString));

      return moment(currentDate)
        .set({ seconds: 0, millisecond: 0 })
        .isBetween(open, close, undefined, '[]');
    });

    return isInWorkingRange && get(isOpenForTheDay(utcDateString.toString()));
  },
});

export const isAvailableForPickup = selectorFamily({
  key: 'isAvailableForPickup',
  get: (utcDateString: string) => ({ get }): boolean => {
    const shopMinimalDelayUntilPickup: moment.Duration | null = get(minimalDelayUntilPickup);

    if (!shopMinimalDelayUntilPickup) return false;

    const now = moment(Date.now()).set({ seconds: 0, millisecond: 0 });

    const momentDate = moment(new Date(Date.parse(utcDateString))).clone();

    const minimalPickUpForTheDay = now.clone().add(shopMinimalDelayUntilPickup);

    // On ne peut pas récuperer une commande dans le passé
    if (momentDate.isSameOrBefore(now)) return false;

    // Si on n'a pas le temps de préparer la commande
    if (momentDate.isBefore(minimalPickUpForTheDay)) return false;

    return get(isOpen(utcDateString));
  },
});

export const isOpenForTheDay = selectorFamily({
  key: 'isOpenForTheDay',
  get: (utcDateString: string) => ({ get }): boolean => {
    const workingRange = get(getOpenTimeSlotsForDate(utcDateString));

    return workingRange.length !== 0;
  },
});

export const getOpenTimeSlotsForDate = selectorFamily({
  key: '',
  get: (utcDateString: string) => ({ get }): MomentWorkingHours[] => {
    const workingDate = new Date(Date.parse(utcDateString));

    let workingRanges: MomentWorkingHours[] = [];
    const workingHoursForToday = get(getWorkingHoursFromDay(utcDateString));

    workingHoursForToday.forEach((workingRange) => {
      const openDate = new Date(workingRange.open);
      const closeDate = new Date(workingRange.close);

      const open: moment.Moment = moment(workingDate)
        .startOf('day')
        .add({ hours: openDate.getUTCHours(), minutes: openDate.getMinutes() });
      const close: moment.Moment = moment(workingDate)
        .startOf('day')
        .add({ hours: closeDate.getUTCHours(), minutes: closeDate.getMinutes() });

      workingRanges.push({ open, close });
    });

    return workingRanges;
  },
});

export const getNextAvailableDay = selector({
  key: 'getNextAvailableDate',
  get: ({ get }): Date | null => {
    const minimalPickup = get(minimalDelayUntilPickup);

    if (minimalPickup === null) return null;
    let nextAvailableDate = new Date(Date.now());

    const nextAvailablePickup = moment(+nextAvailableDate)
      .clone()
      .add(minimalPickup)
      .toDate();

    // Si nous pouvons retirer un colis aujourd'hui alors on return la date
    if (get(isAvailableForPickup(nextAvailablePickup.toString()))) {
      return get(getNextAvailablePickupOption(nextAvailableDate.toString()));
    }

    const isSameDay = (date: Date) => nextAvailableDate.getDay() === date.getDay();

    let nextDay = moment(Date.now()).add(1, 'day').toDate();

    while (!isSameDay(nextDay)) {
      if (get(getWorkingHoursFromDay(nextDay.toString())).length > 0) {
        nextAvailableDate = nextDay;
        return get(getNextAvailablePickupOption(nextAvailableDate.toString()));
      }

      nextDay = moment(nextDay).add(1, 'day').toDate();
    }

    return null;
  },
});

export const getPickupOptions = selectorFamily({
  key: 'getPickupOptions',
  get: (utcDateString: string) => ({ get }): Date[] => {
    const openHours = get(getOpenTimeSlotsForDate(utcDateString));
    const shopPickupInterval = get(pickupInterval);
    const options: Date[] = [];

    openHours.forEach((openRange) => {
      const { open, close } = openRange;

      for (open; open.isSameOrBefore(close); open.add(shopPickupInterval)) {
        const orderDate = moment(new Date(Date.parse(utcDateString))).set({
          hours: open.hours(),
          minutes: open.minutes(),
          seconds: 0,
          millisecond: 0,
        });

        if (get(isAvailableForPickup(orderDate.toDate().toString()))) {
          options.push(orderDate.toDate());
        }
      }
    });

    return options;
  },
});

export const availableDayFromWeek = selector({
  key: 'availableDayFromWeek',
  get: ({ get }): openDays => {
    const today = new Date();
    const week = {} as openDays;

    const isSameDay = (date: Date) => today.getDay() === date.getDay();

    week[getDayNameFromDate(today)] = get(shouldDateBeAvailable(today.toString()));

    let nextDay = moment(Date.now()).add(1, 'day').toDate();

    while (!isSameDay(nextDay)) {
      const shouldBeAvailable = get(shouldDateBeAvailable(nextDay.toString()));
      const currentDay = getDayNameFromDate(nextDay);

      week[currentDay] = shouldBeAvailable;

      nextDay = moment(nextDay).add(1, 'day').toDate();
    }

    return week;
  },
});

export const closedDays = selector({
  key: 'closedDays',
  get: ({ get }): openDays => {
    const today = new Date();
    const week = {} as openDays;

    const isSameDay = (date: Date) => today.getDay() === date.getDay();

    week[getDayNameFromDate(today)] = get(isOpenForTheDay(today.toString()));

    let nextDay = moment(Date.now()).add(1, 'day').toDate();

    while (!isSameDay(nextDay)) {
      const shouldBeAvailable = get(isOpenForTheDay(nextDay.toString()));
      const currentDay = getDayNameFromDate(nextDay);

      week[currentDay] = shouldBeAvailable;

      nextDay = moment(nextDay).add(1, 'day').toDate();
    }

    return week;
  },
});

export const shouldDateBeAvailable = selectorFamily({
  key: 'shouldDateBeAvailable',
  get: (date: string | undefined) => ({ get }): boolean => {
    if (!date) return false;

    const isOpenToday = get(isOpenForTheDay(date));

    const shopMinimalDelayUntilPickup = get(minimalDelayUntilPickup);

    const today = moment(Date.now()).format('MM/DD/YYYY');
    const currentDate = moment(date).format('MM/DD/YYYY');

    const dateIsToday = today === currentDate;

    const dateWithMinimalDelay = moment(date).add(shopMinimalDelayUntilPickup);

    const shopIsAvailableForPickup = get(isAvailableForPickup(dateWithMinimalDelay.toString()));

    // Si le magasin est ouvert, et qu'on est aujourd'hui, mais qu'on a pas de pickup possible, alors renvoit false
    if (dateIsToday && isOpenToday && !shopIsAvailableForPickup) {
      return false;
    }

    // Sinon on retourne is le magasin a des heures d'ouvertures
    return isOpenToday;
  },
});

export const getNextAvailablePickupOption = selectorFamily({
  key: 'getNextAvailablePickupOption',
  get: (utcDateString: string | null) => ({ get }): Date | null => {
    if (!utcDateString) return null;

    const availablePickupHours = get(getPickupOptions(utcDateString));

    if (availablePickupHours.length === 0) {
      return null;
    }

    return availablePickupHours[0];
  },
});

type DayName = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

const weekDays: DayName[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

type MomentWorkingHours = { open: moment.Moment; close: moment.Moment };

const getWorkingHoursFromDay = selectorFamily({
  key: 'getWorkingHoursFromDay',
  get: (dayDateString: string) => ({ get }): WorkingHours[] => {
    const date = new Date(Date.parse(dayDateString));
    const dayName = getDayNameFromDate(date);

    const weekPickupHours = get(pickupHours);

    if (!Object.values(weekPickupHours).length) return [];
    return weekPickupHours[dayName];
  },
});

const getDayNameFromDate = (date: Date): DayName => {
  return weekDays[date.getDay()];
};
