import styled from 'styled-components';
import { ReactComponent as CloseLight } from '../../assets/closeLight.svg';
import { ReactComponent as Chevron } from '../../assets/chevron.svg';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Modal,
  withStyles,
} from '@material-ui/core';

export const ModalContainer = styled(Modal)`
  overflow: scroll;

  & .MuiPaper-root {
    background-color: transparent;
  }
`;

export const CategoryContainer = styled.div`
  z-index: 1;
  top: 0;
  left: 0;
  position: absolute;
  width: 315px;
  min-height: 100%;
  background-color: var(--contrast);
  overflow: hidden;
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 315px;
  height: 100px;
  background-color: var(--neutralLighter);
`;

export const CategoryBody = styled.div`
  padding: 0 20px;
`;

export const CategoryTitle = styled.span`
  margin-left: 20px;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CloseContainer = styled(CloseLight)`
  width: 19px;
  height: 20px;
  margin: 0 20px 40px 0;
  cursor: pointer;
`;

export const AccordionContainer = styled(MuiAccordion)`
  width: 315px;
  height: 60px;
`;

export const AccordionTitle = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

export const DecorationContainer = styled.div`
  z-index: -1;
  position: absolute;
  bottom: -5px;
  opacity: 0.4;
`;

export const ChevronContainer = styled(Chevron)`
  width: 7px;
  height: 15px;
`;

export const AccordionSubCategory = styled.p`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 0;
  cursor: pointer;
`;

export const Dot = styled.span`
  height: 3px;
  width: 3px;
  background-color: var(--primaryFont);
  border-radius: 50%;
  margin: 0 10px 3px 0;
  display: inline-block;
`;

export const Accordion = withStyles({
  root: {
    borderBottom: `1px solid var(--primaryLighter)`,
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    height: 60,
    padding: '0 20px 0 0',
    '&$expanded': {
      minHeight: 0,
      height: 60,
    },
    '&$expanded .MuiAccordionSummary-expandIcon': {
      transform: 'rotate(90deg)',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles({
  root: {
    padding: '0 0 20px 0',
    display: 'block',
    backgroundColor: 'var(--contrast)',
  },
})(MuiAccordionDetails);
