import React, { useState } from 'react';
import Header from '../components/Header/Header';
import MenuBar from '../components/MenuBar/MenuBar';
import Catalog from '../components/Catalog/Catalog';
import CategoriesModal from '../components/CategoriesModal/CategoriesModal';
import CartModal from '../components/CartModal/CartModal';
import { Category } from '../models/dto';
import ValidationDialog from '../components/ValidationDialog/ValidationDialog';
import ResponseStatusComponent, {
  ResponseStatus,
} from '../components/ResponseStatus/ResponseStatus';
import { useQuery } from '@apollo/client';
import { GET_SHOP_INFO, GET_PRODUCTS } from '../graphQl/GQLExports';
import { Product } from '../graphQl/api_generated';
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { useRecoilState } from 'recoil';
import { shopInfoSelector } from '../store/shop/shop.store';
import parseCategory from '../utils/categoryParser';
import { WheelBox } from './ShopPage.style';
import CircularProgress from '@material-ui/core/CircularProgress';

function ShopPage(): JSX.Element {
  const responseStatusState = useState(ResponseStatus.PENDING);
  const { shopName }: { shopName: string } = useParams();
  const [shop, setShop] = useRecoilState(shopInfoSelector);
  const [errorFetch, setErrorFetch] = useState(false);
  const [shopError, setShopError] = useState(false);

  const shopInfoSet = Object.entries(shop).length > 0;

  const { data: shopInfo, loading: loadingShop } = useQuery(GET_SHOP_INFO, {
    variables: { shopName },
    skip: shopInfoSet,
    onError: (e) => {
      console.log(e);
      setShopError(true);
    },
    onCompleted: ({ getShopInformations }) => {
      setShop(getShopInformations);
    },
  });

  const { data: gqlProducts } = useQuery(GET_PRODUCTS, {
    variables: { shopName },
    onError: (e) => {
      console.log(e);
      setErrorFetch(true);
    },
  });

  const products: Product[] = (gqlProducts?.getProducts.products || []).filter(
    (p: Product) => !p.isDraft
  );

  const categories: Category[] = parseCategory(products);

  return (
    <>
      {loadingShop && (
        <WheelBox>
          <CircularProgress />
        </WheelBox>
      )}

      {shopInfoSet || shopInfo ? (
        <div>
          <Header />
          <MenuBar products={products} />
          <Catalog products={products} />
          <CategoriesModal categories={categories} />
          <CartModal />
          <ValidationDialog responseStatusState={responseStatusState} />
          <ResponseStatusComponent
            responseStatusState={responseStatusState}
            errorFetch={errorFetch}
          />
        </div>
      ) : (
        shopError && (
          <Alert severity="error" style={{ margin: '40px' }}>
            La boutique à laquelle vous essayez d'accéder n'existe pas.
          </Alert>
        )
      )}
    </>
  );
}

export default ShopPage;
