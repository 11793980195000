import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation($order: OrderCreate!) {
    submitOrder(orderCreate: $order) {
      reference
      email
      lastname
      firstname
      phone
      pickupDate
      status
      createdAt
      updatedAt
      hasBag
      amount
    }
  }
`;

export const GET_SHOP_INFO = gql`
  query($shopName: String!) {
    getShopInformations(shopName: $shopName) {
      id
      name
      pickupHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      phone
      pickupDelayMinutes
      pickupTimeIncrementMinutes
      openHours {
        week {
          monday {
            open
            close
          }
          tuesday {
            open
            close
          }
          wednesday {
            open
            close
          }
          thursday {
            open
            close
          }
          friday {
            open
            close
          }
          saturday {
            open
            close
          }
          sunday {
            open
            close
          }
        }
      }
      theme {
        primaryLighter
        primaryFontColor
        contrast
        primary
      }
      address
      kraftBagsPrice
      email
      defaultCurrency
      ownerId
      facebookLink
      twitterLink
      instagramLink
      headerDecorationUrl
      categoriesDecorationUrl
      backgroundDecorationUrl
      validationDecorationUrl
      logoUrl
      orderSummaryDecorationUrl
      cartDecorationUrl
      placeholderUrl
      primaryFont
      secondaryFont
      loyaltyProgram {
        isEnabled
        pointsDescription
        pointsName
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query($shopName: String!) {
    getProducts(shopName: $shopName) {
      products {
        id
        shopId
        createdAt
        updatedAt
        deletedAt
        category
        subcategory
        name
        isAvailable
        sellingPrice
        sellingMeasureUnit
        capacity
        wholesaleMeasureUnit
        displayedQuantity
        quantityIncrement
        pictureUrl
        wholesalePrice
        displayedPrice
        isDraft
        needsJar
        displayedMeasureUnit
        loyaltyPoints
      }
    }
  }
`;
