import { Product } from '../graphQl/api_generated';
import { Category } from '../models/dto';

const parseCategory = (products: Product[]): Category[] => {
  const categories: Category[] = [];

  products.forEach((product: Product) => {
    if (!product.category) return;

    const categoriesName = categories.map((category) => category.name);
    if (categoriesName.includes(product.category)) {
      const indexOfTheCategory = categoriesName.indexOf(product.category);
      const subCategoriesOfThisExistingCategory = categories[indexOfTheCategory].subCategories;

      if (!subCategoriesOfThisExistingCategory.includes(product.subcategory)) {
        product.subcategory &&
          categories[indexOfTheCategory].subCategories.push(product.subcategory);
      }
    } else {
      product.subcategory
        ? categories.push({
            name: product.category,
            subCategories: [product.subcategory],
          })
        : categories.push({
            name: product.category,
            subCategories: [],
          });
    }
  });

  return categories;
};

export default parseCategory;
