import { atom } from 'recoil';

export const isCategoriesModalDisplayedState = atom({
  key: 'isCategoriesModalDisplayedState',
  default: false,
});

export const isCartModalDisplayedState = atom({
  key: 'isCartModalDisplayedState',
  default: false,
});

export const isValidationDialogDisplayedState = atom({
  key: 'isValidationDialogDisplayedState',
  default: false,
});

export const isResponseStatusDialogDisplayedState = atom({
  key: 'isResponseStatusDialogDisplayedState',
  default: false,
});

export const isShopInformationsModalDisplayedState = atom({
  key: 'isShopInformationsModalDisplayedState',
  default: false,
});
