import {
  WholesaleMeasureUnitProduct,
  DisplayedUnit,
  ProductDisplayedUnit,
} from '../graphQl/api_generated';

const frontValues = {
  LITRE: 'L',
  MILLILITRE: 'ml',
  KILOGRAMME: 'kg',
  GRAMME: 'g',
  UNIT: 'u',
};

export const getShortUnit = (
  unit: ProductDisplayedUnit | WholesaleMeasureUnitProduct | DisplayedUnit
) => (unit in frontValues ? frontValues[unit] : '');
