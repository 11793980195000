import React, { useState } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
  isCartModalDisplayedState,
  isCategoriesModalDisplayedState,
} from '../../store/dialog.store';
import { cartItemListState } from '../../store/cart/cart.store';
import { filterState } from '../../store/filter.store';
import {
  AutocompleteContainer,
  CategoryContainer,
  SubHeaderContainer,
  CategoryIconContainer,
  CartIcon,
  CssTextField,
  LeftContainer,
  RightContainer,
  SearchMagnifyingGlass,
  CartCount,
  CartBox,
} from './MenuBar.style';
import { Product } from '../../graphQl/api_generated';
import { createFilterOptions } from '@material-ui/lab';

function MenuBar({ products }: { products: Product[] }): JSX.Element {
  const setIsCategoriesModalDisplayed = useSetRecoilState(isCategoriesModalDisplayedState);
  const setIsCartModalDisplayed = useSetRecoilState(isCartModalDisplayedState);
  const [filter, setFilter] = useRecoilState(filterState);
  const [tmpFilter, setTmpFilter] = useState('');
  const itemsInCart = useRecoilValue(cartItemListState).length;

  let allProductNames = products.filter((product) => product.isAvailable).map((p) => p.name);

  return (
    <SubHeaderContainer>
      <LeftContainer onClick={() => setIsCategoriesModalDisplayed(true)}>
        <CategoryIconContainer />
        <CategoryContainer>Catégories</CategoryContainer>
      </LeftContainer>
      <RightContainer>
        <AutocompleteContainer
          filterOptions={createFilterOptions({
            ignoreCase: true,
          })}
          fullWidth={false}
          value={filter}
          freeSolo
          options={allProductNames}
          onChange={(_: any, newValue: any) => setFilter(newValue || '')}
          renderInput={(params) => (
            <CssTextField
              {...params}
              margin="none"
              placeholder="Rechercher"
              size="small"
              onInput={(event: any) => setTmpFilter(event.target.value || '')}
              variant="outlined"
            />
          )}
        />
        <SearchMagnifyingGlass onClick={() => setFilter(tmpFilter)} />

        <CartBox onClick={() => setIsCartModalDisplayed(true)}>
          <CartIcon />
          {itemsInCart > 0 && <CartCount>{itemsInCart < 99 ? itemsInCart : '+99'}</CartCount>}
        </CartBox>
      </RightContainer>
    </SubHeaderContainer>
  );
}

export default MenuBar;
