import { FC } from 'react';
import { DialogTitle } from '@material-ui/core';
import {
  CloseContainer,
  DialogContainer,
  TitleContainer,
  TopContainer,
} from '../ValidationDialog/ValidationDialog.style';
import {
  ShopName,
  ShopInfo,
  AddressText,
  DayRow,
  Grid,
  SocialNetworksBox,
} from './ShopInformationsModal.style';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isShopInformationsModalDisplayedState } from '../../store/dialog.store';
import { ShopInformations } from '../../graphQl/api_generated';
import { ReactComponent as Instagram } from '../../assets/instagram-icon.svg';
import { ReactComponent as Twitter } from '../../assets/twitter-icon.svg';
import { ReactComponent as Facebook } from '../../assets/facebook-icon.svg';
import { shopInfoSelector } from '../../store/shop/shop.store';

type dayName = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

const dayTranslations: any = {
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mercredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
  sunday: 'dimanche',
};

const ShopInformationsModal: FC = () => {
  const [isShopInformationsModalDisplayed, setIsShopInformationsModalDisplayed] = useRecoilState(
    isShopInformationsModalDisplayedState
  );

  const shopInformations = useRecoilValue(shopInfoSelector) as ShopInformations;

  const openHours = shopInformations.openHours || { week: {} };
  const weekDays = Object.keys(openHours.week);
  weekDays.shift();

  const formatUTCTime = (date: Date) => {
    const hoursToDisplay = new Date(date).getUTCHours();
    const minutesToDisplay = new Date(date).getUTCMinutes();

    const formattedTime = `${hoursToDisplay
      .toString()
      .padStart(2, '0')}:${minutesToDisplay.toString().padStart(2, '0')}`;

    return formattedTime;
  };

  return (
    <DialogContainer
      onClose={() => setIsShopInformationsModalDisplayed(false)}
      aria-labelledby="simple-dialog-title"
      open={isShopInformationsModalDisplayed}
      style={{ margin: 0 }}>
      <TopContainer>
        <TitleContainer>
          <DialogTitle>Informations boutique</DialogTitle>
          <CloseContainer onClick={() => setIsShopInformationsModalDisplayed(false)} />
        </TitleContainer>
        <ShopName>{shopInformations.name}</ShopName>
        <AddressText>{shopInformations.address}</AddressText>
        <ShopInfo>{shopInformations.phone}</ShopInfo>
        <ShopInfo>{shopInformations.email}</ShopInfo>
      </TopContainer>

      <Grid>
        {weekDays.map((day, index) => {
          const workingHours = shopInformations.openHours.week[day as dayName];

          return (
            <DayRow
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? 'var(--primaryLighter)' : 'var(--neutral)',
              }}>
              <span>{dayTranslations[day]} </span>
              <span>
                {workingHours.length <= 0 && 'fermé'}
                {workingHours.map(
                  (schedule, index) =>
                    `${index !== 0 ? ' / ' : ''} ${formatUTCTime(schedule.open)} - ${formatUTCTime(
                      schedule.close
                    )}`
                )}
              </span>
            </DayRow>
          );
        })}
      </Grid>

      <SocialNetworksBox>
        {shopInformations.instagramLink && (
          <a href={shopInformations.instagramLink} target="_blank" rel="noreferrer">
            <Instagram />
          </a>
        )}

        {shopInformations.twitterLink && (
          <a href={shopInformations.twitterLink} target="_blank" rel="noreferrer">
            <Twitter />
          </a>
        )}

        {shopInformations.facebookLink && (
          <a href={shopInformations.facebookLink} target="_blank" rel="noreferrer">
            <Facebook />
          </a>
        )}
      </SocialNetworksBox>
    </DialogContainer>
  );
};

export default ShopInformationsModal;
