import { useRecoilValue } from 'recoil';
import {
  isOpen as isShopOpen,
  isAvailableForPickup as isShopAvailableForPickup,
  isOpenForTheDay as isShopOpenForTheDay,
  getNextAvailableDay as getShopNextAvailableDay,
  getPickupOptions as getShopPickupOption,
  getNextAvailablePickupOption as getNextShopAvailablePickupOption,
} from '../../store/shop/shop.store';

export function useIsOpenForTheDay(date: Date) {
  const isOpenForTheDay = useRecoilValue(isShopOpenForTheDay(date.toUTCString()));
  return isOpenForTheDay;
}

export function useIsAvailableForPickup(date: Date) {
  const isAvailableForPickup = useRecoilValue(isShopAvailableForPickup(date.toUTCString()));
  return isAvailableForPickup;
}

export function useGeneratePickUpOptions(date: Date) {
  const getPickupOption = useRecoilValue(getShopPickupOption(date.toUTCString()));
  return getPickupOption;
}

export function useGetNextAvailablePickupOption(date: Date): Date {
  const getNextAvailablePickupOption = useRecoilValue(
    getNextShopAvailablePickupOption(date?.toUTCString() || null)
  );

  return getNextAvailablePickupOption ? getNextAvailablePickupOption : new Date();
}

export function useIsOpen(date: Date) {
  const isOpen = useRecoilValue(isShopOpen(date.toUTCString()));
  return isOpen;
}

export function useGetNextAvailableDay(): Date | null {
  return useRecoilValue(getShopNextAvailableDay);
}
