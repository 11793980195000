import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ShopInformations } from '../../graphQl/api_generated';
import { shopInfoSelector } from '../../store/shop/shop.store';
import { Button, LoyaltyDescriptionModal, CloseIcon } from './styles';

type Props = { points: number; disabled?: boolean };

const LoyaltyPoints = ({ points, disabled }: Props) => {
  const shopInformations = useRecoilValue(shopInfoSelector) as ShopInformations;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const closeModal = () => isModalOpen && setIsModalOpen(false);

    window.addEventListener('mousedown', closeModal);

    return () => {
      window.removeEventListener('mousedown', closeModal);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  if (!shopInformations.loyaltyProgram?.isEnabled || points <= 0) return null;

  return (
    <Button onClick={() => setIsModalOpen((prev) => !prev)} disabled={disabled}>
      + {points} {shopInformations.loyaltyProgram.pointsName}
      {isModalOpen && (
        <LoyaltyDescriptionModal>
          {shopInformations.loyaltyProgram.pointsDescription}
          <CloseIcon>X</CloseIcon>
        </LoyaltyDescriptionModal>
      )}
    </Button>
  );
};

export default LoyaltyPoints;
