import { Button, Dialog, TextField, withStyles, FormControl } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import { ReactComponent as CloseLight } from '../../assets/closeLight.svg';

export const DialogContainer = withStyles({
  root: {
    '& .MuiDialog-paper': {
      width: '316px',
      margin: '29px',
      padding: '0',
    },
    '& .MuiDialogTitle-root': {
      padding: '0',
    },
    '& .MuiTypography-root': {
      fontFamily: 'var(--secondaryFont)',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '24px',
      letterSpacing: '0em',
      textAlign: 'left',
    },
    '& .MuiPaper-rounded': {
      borderRadius: '10px',
    },
  },
})(Dialog);

export const muiDialogOveride = {
  MuiDialog: {
    root: {
      '& .MuiPaper-root': {
        minWidth: '316px',
      },
      '& .MuiToolbar-regular, & .MuiPickersDay-daySelected, & .MuiPickersDay-daySelected:hover': {
        backgroundColor: 'var(--primary)',
      },
    },
  },
};

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseContainer = styled(CloseLight)`
  width: 19px;
  height: 20px;
  cursor: pointer;
`;

export const VerificationContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
`;

export const TopContainer = styled.div`
  padding: 20px 20px 0 20px;
`;

export const BottomContainer = styled.div``;

export const InformationContainer = styled.div`
  margin-bottom: 20px;
`;

const inputContainerStyles = {
  width: '100%',
  marginTop: '10px',
};

const inputTextStyles = {
  fontFamily: 'var(--primaryFont)',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '300',
  lineHeight: '18px',
  letterSpacing: '0em',
  textAlign: 'left',
};

export const TextFieldContainer = withStyles({
  root: {
    ...inputContainerStyles,
    '& .MuiInputLabel-root': inputTextStyles,
  },
})(TextField);

export const DatePickerContainer = withStyles({
  root: {
    ...inputContainerStyles,
    '& .MuiInput-input': inputTextStyles,
  },
})(DatePicker);

const wrapperFormStyle = { width: '45%', marginTop: '10px' };

export const FormWrapper = withStyles({
  root: {
    ...wrapperFormStyle,
  },
})(FormControl);

export const TextFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CartSummary = styled.div`
  padding: 20px;
  background-color: var(--neutralLighter);
  overflow: hidden;
  position: relative;
`;

export const SummaryContainer = styled.div`
  border-bottom: 1px solid var(--neutralLight);
`;

export const SummaryLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const SummaryTitle = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--primaryFontColor);
`;

export const SummaryData = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: var(--primaryFontColor);
`;

export const ValidationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
`;

export const PickupDateHintContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 4px;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props: { isValid: boolean }) =>
    props.isValid ? 'var(--primaryFont)' : 'var(--error)'};
`;

export const ValidationTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ValidationPrice = styled.div`
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
`;

export const RecapDecorationContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -2px;
  opacity: 0.8;
  width: 116px;
  height: 115px;
`;

export const ButtonContainer = styled(Button)`
  width: 280px !important;
  height: 60px !important;
  margin: 20px 0 !important;
  background: var(--primary) !important;
  border-radius: 5px !important;
  z-index: 1;
  box-shadow: none !important;
  & .MuiButton-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: none;
  }
`;
