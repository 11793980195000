import { LogoBox } from './MainPage.style';
import { ReactComponent as Logo } from '../../assets/ioshop-logo.svg';

const HomePage = (): JSX.Element => {
  return (
    <LogoBox>
      <Logo />
    </LogoBox>
  );
};

export default HomePage;
