import { atom, selector } from 'recoil';
import { cartItemListState } from './cart.store';
import { bagPrice } from '../../config.json';
import { DisplayedUnit } from '../../graphQl/api_generated';
import { shopInfoSelector } from '../shop/shop.store';

export const KRAFT_BAG_PRICE: number = Number(bagPrice);

export const craftBagNotNeededState = atom({
  key: 'craftBagNotNeededState',
  default: false,
});

export const bagsNumberState = selector({
  key: 'bagsNumberState',
  get: ({ get }) => {
    if (get(craftBagNotNeededState)) {
      return 0;
    }
    const cartItemList = get(cartItemListState);
    return cartItemList.filter(
      (cartItem /* TODO: There's probably something wrong with the types here */) =>
        cartItem.product.displayedMeasureUnit.valueOf() === DisplayedUnit.Gramme.valueOf()
    ).length;
  },
});

export const bagsTotalPriceState = selector({
  key: 'bagsTotalPriceState',
  get: ({ get }) => {
    const kraftBagsPrice = get(shopInfoSelector)?.kraftBagsPrice || KRAFT_BAG_PRICE;
    return get(bagsNumberState) * kraftBagsPrice;
  },
});
