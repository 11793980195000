import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from '../src/translations';

i18n.use(initReactI18next).init(
  {
    resources,
    lng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  },
  // eslint-disable-next-line react/no-render-return-value
  () => ReactDOM.render(<App />, document.getElementById('root'))
);
