import React, { useMemo } from 'react';
import {
  HeaderContainer,
  LogoContainer,
  HeaderDecorationContainer,
  ShopInfosBox,
  Iicon,
} from './Header.style';
import ShopInformationsModal from '../ShopInformationsModal/ShopInformationsModal';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isShopInformationsModalDisplayedState } from '../../store/dialog.store';
import { ReactComponent as ShopInformationsIcon } from '../../assets/shop-informations-icon.svg';
import { shopInfoSelector } from '../../store/shop/shop.store';
import { defaultShopInformations } from '../../utils/common-data';
import { Helmet } from './Helmet';

function Header(): JSX.Element {
  const setIsShopInformationsModalDisplayed = useSetRecoilState(
    isShopInformationsModalDisplayedState
  );

  const [shopInfos] = useRecoilState(shopInfoSelector);
  const shop = useMemo(() => Object.assign(defaultShopInformations, shopInfos), [shopInfos]);

  const headerDecoration = useMemo(() => shop.headerDecorationUrl, [shop.headerDecorationUrl]);

  return (
    <>
      <Helmet name={shop.name} logoUrl={shop.logoUrl} />

      <HeaderContainer>
        <ShopInfosBox onClick={() => setIsShopInformationsModalDisplayed(true)}>
          <ShopInformationsIcon />
          <Iicon>i</Iicon>
        </ShopInfosBox>
        {!!shop.logoUrl && <LogoContainer alt="logo" src={shop.logoUrl} />}

        {!!headerDecoration && (
          <HeaderDecorationContainer>
            <img src={headerDecoration} alt="HeaderDecoration" />
          </HeaderDecorationContainer>
        )}

        <ShopInformationsModal />
      </HeaderContainer>
    </>
  );
}
export default Header;
