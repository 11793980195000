import { atom } from 'recoil';

export const orderFirstname = atom<string>({
  key: 'orderFirstname',
  default: '',
});

export const orderLastname = atom<string>({
  key: 'orderLastname',
  default: '',
});

export const orderEmail = atom<string>({
  key: 'orderEmail',
  default: '',
});

export const orderPhone = atom<string>({
  key: 'orderPhone',
  default: '',
});

export const orderPickupDate = atom<Date>({
  key: 'orderPickupDay',
  default: new Date(),
});

export const orderConsentToLoyaltyProgram = atom<boolean>({
  key: 'orderConsentToLoyaltyProgram',
  default: false,
});
