import { DisplayedUnit, OrderCreate, OrderProductInput } from '../../graphQl/api_generated';
import { formatFloatTwoDigits, getCartItemPrice } from '../../utils/price';
import { CartItem } from '../../models/dto';

function formatOrder(orderData: {
  lastname: string;
  firstname: string;
  email: string;
  phone: string;
  amount: number;
  hasBag: boolean;
  pickupDate: Date;
  products: CartItem[];
  shopId: string;
  consentToLoyaltyProgram: boolean;
}): OrderCreate {
  const formattedPickup = new Date(orderData.pickupDate).toISOString();

  const products: Array<OrderProductInput> = orderData.products.map(({ product, quantity }) => {
    const productUnit = product.displayedMeasureUnit.valueOf();

    return {
      reference: product.name,
      price: formatFloatTwoDigits(getCartItemPrice({ product, quantity })),
      /* FIXME: This is very surprising */
      unit: productUnit as DisplayedUnit,
      quantity: quantity,
    };
  });

  return {
    shopId: orderData.shopId,
    lastname: orderData.lastname,
    firstname: orderData.firstname,
    email: orderData.email,
    phone: orderData.phone,
    amount: formatFloatTwoDigits(orderData.amount),
    hasBag: orderData.hasBag,
    pickupDate: formattedPickup,
    products: products,
    consentToLoyaltyProgram: orderData.consentToLoyaltyProgram,
  };
}

export { formatOrder };
